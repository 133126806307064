import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, Matches, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { SchoolModel } from "@/models";
import { Common } from "../Common";

export class SchoolForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) @Matches(/^.[A-Za-z0-9]+$/, { message: '請輸入英數' }) @MaxLength(10, { message: "最多輸入10字" }) codeName = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) crid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) address = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = '1';
    @IsDefined() @IsNotEmpty({ message: "必填" }) category = '2';
    sid = '';
    token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminSchoolAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminSchoolAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private schoolForm = new SchoolForm();
    private cityList: object = {};
    private regionList: object = {};
    private sid = "";
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private errorMsgMap: { [key: string]: string } = {
        codeName: '',
        name: '',
        cid: '',
        crid: '',
        address: '',
        active: '',
        category: '',
    };

    public async created() {
        const item = {
            sid: this.$route.query.sid,
            token: this.token,
        }
        const editData = await SchoolModel.get(item) as response;
        this.assignDataToForm(editData);

        this.cityList = await Common.getAllCity(); //取得所有縣市
        const cid = this.schoolForm.cid;
        this.regionList = await Common.getRegion(cid);
    }

    private assignDataToForm(data: response) {
        Common.checkToken(data.ERR_CODE as number);

        const item = data.info as { [key: string]: string };
        this.schoolForm.sid = item.sid;
        this.schoolForm.codeName = item.codeName;
        this.schoolForm.name = item.name;
        this.schoolForm.cid = item.cid;
        this.schoolForm.crid = item.crid;
        this.schoolForm.address = item.address;
        this.schoolForm.active = item.active;
        this.schoolForm.category = item.category;
    }

    //取得該縣市鄉鎮區域
    private async getRegion() {
        this.schoolForm.crid = '';
        const cid = this.schoolForm.cid;
        this.regionList = await Common.getRegion(cid);
    }

    private validateAndSubmit() {
        validate(this.schoolForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        codeName: '',
                        name: '',
                        cid: '',
                        crid: '',
                        address: '',
                        active: '',
                        category: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = JSON.parse(JSON.stringify(this.schoolForm));

                    this.errNo = await SchoolModel.edit(item);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }
    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/school");
        }
    }
}
